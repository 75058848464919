// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ---------- SIGN IN ---------- */
.sign-in__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sign-in__backdrop {
  position: absolute;
  inset: 0;
}

.sign-in__wrapper form {
  width: 24rem;
  max-width: 90%;
  z-index: 1;
  animation: showSignInForm 1s;
}

.sign-in__wrapper form img {
  width: 60%;
}

@keyframes showSignInForm {
  0%,
  30% {
    transform: translate(0, -150%);
  }
  70%,
  90% {
    transform: translate(0, 1rem);
  }
  80%,
  100% {
    transform: translate(0, 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Login/login.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kCAAkC;EAClC,4BAA4B;EAC5B,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;;IAEE,8BAA8B;EAChC;EACA;;IAEE,6BAA6B;EAC/B;EACA;;IAEE,0BAA0B;EAC5B;AACF","sourcesContent":["/* ---------- SIGN IN ---------- */\n.sign-in__wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  height: 80vh;\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  position: relative;\n}\n\n.sign-in__backdrop {\n  position: absolute;\n  inset: 0;\n}\n\n.sign-in__wrapper form {\n  width: 24rem;\n  max-width: 90%;\n  z-index: 1;\n  animation: showSignInForm 1s;\n}\n\n.sign-in__wrapper form img {\n  width: 60%;\n}\n\n@keyframes showSignInForm {\n  0%,\n  30% {\n    transform: translate(0, -150%);\n  }\n  70%,\n  90% {\n    transform: translate(0, 1rem);\n  }\n  80%,\n  100% {\n    transform: translate(0, 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
